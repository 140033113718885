import React from "react";

const DismissIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4375 2.97309L15.0269 0.5625L8.99997 6.58941L2.97306 0.5625L0.562469 2.97309L6.58938 9L0.562469 15.0269L2.97306 17.4375L8.99997 11.4106L15.0269 17.4375L17.4375 15.0269L11.4106 9L17.4375 2.97309Z"
        fill="black"
      />
    </svg>
  );
};

export default DismissIcon;
