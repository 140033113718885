import styles from "./styles.module.scss";
import Link from "link";
import DismissIcon from "assets/dismiss_icon.svg.jsx";
import Analytics from "analytics";

const RedirectSignpost = () => {
  const hideRedirectSignpost = !!localStorage.getItem("cci_signpost");
  const [showRedirectSignpost, setShowRedirectSignpost] = useState(
    hideRedirectSignpost ? false : true
  );

  const dismissRedirectSignpost = () => {
    localStorage.setItem("cci_signpost", Date.now());
    setShowRedirectSignpost(false);
  };

  const handleClick = () => {
    Analytics.clickCciSignpost();
  };

  return (
    <>
      {showRedirectSignpost && (
        <div className={styles.overlay}>
          <div className={styles.content}>
            <div className={styles.copy}>
              <p>
                Looking for Code Club International? Learn about running a
                coding club at{" "}
                <Link
                  onClick={handleClick}
                  className={styles.link}
                  href="https://international.codeclub.org/"
                >
                  international.codeclub.org
                </Link>
              </p>
            </div>
            <div className={styles.dismiss_wrapper}>
              <button
                onClick={dismissRedirectSignpost}
                className={styles.dismiss_button}
              >
                <DismissIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RedirectSignpost;
